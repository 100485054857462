<!-- eslint-disable import/no-cycle -->
<template>
  <div class="center-screen">
    <h1>
      Welcome back, {{ userLogin.firstName }}

    </h1>
  </div>
</template>

<script>
import { BRow, BCol, BCardText } from 'bootstrap-vue'

// eslint-disable-next-line import/no-cycle
import { getUserData } from '@/auth/utils'
import { mapGetters } from 'vuex'
import * as config from '../../utils/config'
import { authService } from "@/auth";
import store from "@/store";

export default {
  components: {
    BCardText,
    BRow,
    BCol,
  },

  data() {
    return {
      data: {
        config,
        congratulations: {},
      },
    }
  },
  computed: {
    ...mapGetters('app', ['regionOptions']),
    ...mapGetters('authenticate', ['userLogin']),
    getNameUser() {
      const userData = JSON.parse(localStorage.getItem(config.USER))
      return `${userData?.firstName}`
    },
  },
  mounted() {
    const isLoginSSO = sessionStorage.getItem(config.IS_LOGIN_SSO);

    if (isLoginSSO) {
      const loading = document.getElementById("loading-bg");
      const app = document.getElementById("app");

      loading.style.display = "block";
      app.style.display = "none";

      authService.handleLoginRedirect().then(() => {
        setTimeout(() => {
          store.dispatch("authenticate/initAuth");
          window.history.pushState({}, document.title, location.pathname);

          loading.style.display = "none";
          app.style.display = "block";

          sessionStorage.removeItem(config.IS_LOGIN_SSO);
        }, 3000);
      });
    }
  },
}
</script>

<style lang="scss">
.center-screen {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 50vh;
}
@import '@core/scss/vue/pages/dashboard-ecommerce.scss';
@import '@core/scss/vue/libs/chart-apex.scss';
</style>
